
import { defineComponent } from "vue";
import RegisterForm from "@/views/Onboarding/Register/RegisterForm.vue";

export default defineComponent({
  components: { RegisterForm },

  setup() {
    return {};
  },
});
